import { forEach, isString } from 'lodash';
import queryString from 'query-string';

const PLATFORM = {
  web: 'web',
  ios: 'ios',
  android: 'android',
};

let clientPlatform = PLATFORM.web;
let iosVersion = 0;

// This is used in App.tsx
export const setClientPlatform = platform => {
  clientPlatform = PLATFORM[platform];
};

export const setIosVersion = version => {
  iosVersion = version;
};

const checkStatus = response => {
  if (response.ok) {
    return response.json();
  }
  // 409 status codes are handled in .then() statements with an "isError" if-check
  // as these are expected errors
  if (response.status === 409) {
    return response.json().then(errorJson => ({ ...errorJson, isError: true }));
  }
  return response.text().then(errorString => {
    let errorJson = errorString;

    try {
      errorJson = JSON.parse(errorString);
    } catch (failedJsonParseError) {
      // if JSON.parse() fails, just throw the original error string directly
      throw new Error(errorString);
    }

    const err = new Error(
      `client http request failed | ${errorJson.requestMethod} request to ${
        errorJson.requestUrl
      }${
        response.statusText && isString(response.statusText)
          ? ` | ${response.statusText}`
          : ''
      }`,
    );
    err.status = response.status;
    forEach(errorJson, (value, key) => {
      err[key] = value;
    });
    throw err;
  });
};

export default (
  requestMethod,
  url,
  params = {},
  body = null,
  customRequest = {},
  stringify = true,
  platform = undefined,
) => {
  const qsOptions = {
    arrayFormat: 'bracket',
  };
  if (clientPlatform === PLATFORM.ios && iosVersion > 16) {
    qsOptions.encode = false;
  }

  const newUrl = `${url}?${queryString.stringify(params, qsOptions)}`;
  const request = {
    method: requestMethod,
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    ...customRequest,
  };

  request.headers['lifeloop-client-platform'] = platform || clientPlatform;

  // firefox throws a fit if the body key exists for GET requests, even if it's null
  if (body && stringify) {
    request.body = JSON.stringify(body);
  } else if (body) {
    request.body = body;
  }
  return fetch(newUrl, request).then(checkStatus);
};
